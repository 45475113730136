import { OnboardingStageName } from '@presto-assistant/api_types/api/auth/login';

const onboardingPaths: Record<OnboardingStageName, OnboardingStageName> = {
  accept_terms: 'accept_terms',
  acceptable_use_agreement: 'acceptable_use_agreement',
  district_info: 'district_info',
  end_of_year_warning: 'end_of_year_warning',
  extra_verification_needed: 'extra_verification_needed',
  member_info: 'member_info',
  organization_info: 'organization_info',
  successor_organization: 'successor_organization',
  user_contact_info: 'user_contact_info',
  user_profile_info: 'user_profile_info',
  verify_email: 'verify_email',
};

const downloadDirectory = 'downloads';

export const DOWNLOADS = {
  ACCEPTABLE_USE_POLICY_AGREEMENT: `/${downloadDirectory}/Presto%20Assistant%20Acceptable%20Use%20Policy%20Agreement.pdf`,
};

export const PATHS = {
  ADULTS_WITH_PERMISSIONS: 'adults_with_permissions',
  BARCODE_SCAN: 'barcode_scan',
  BILLING: 'billing',
  CALENDAR: 'calendar',
  CHECKLISTS: 'checklists',
  CHECKLISTS_NEW: 'checklists/new',
  CHECKOUTS: 'checkouts',
  COMMUNICATION: 'communication',
  COMMUNICATION_SETTINGS: 'settings/communication',
  CREATE_USER_PROFILE: 'create_user_profile',
  DASHBOARD: 'dashboard',
  DIRECTORS: 'people/directors',
  DIRECTOR_PERMISSIONS: 'director_permissions',
  DISTRICTS: 'districts',
  DISTRICT_ADMIN: 'district_admin',
  DISTRICT_ASSISTANTS: 'district_assistants',
  DYNAMIC_FIELDS: 'dynamic_fields',
  EMAIL: 'email',
  EMAIL_DRAFTS: 'email/drafts',
  EMAIL_NEW: 'email/new',
  EMAIL_SCHEDULED: 'email/scheduled',
  EMAIL_SIGNATURE: 'email_signature',
  EMAIL_SUPPRESSIONS: 'email/suppressions',
  END_OF_YEAR: 'end_of_year_process',
  END_OF_YEAR_COMPLETED: 'end_of_year_process_completed',
  FEEDBACK: 'feedback',
  FILES: 'files',
  FINANCES: 'finances',
  FINANCIAL_ACCOUNTS: 'finances/accounts',
  FINANCIAL_ACCOUNTS_NEW: 'finances/accounts/new',
  FINANCIAL_CREDITS: 'finances/credits',
  FINANCIAL_CREDITS_NEW: 'finances/credits/new',
  FINANCIAL_DEPOSITS: 'finances/deposits',
  FINANCIAL_DEPOSITS_NEW: 'finances/deposits/new',
  FINANCIAL_FEES: 'finances/fees',
  FINANCIAL_FEES_NEW: 'finances/fees/new',
  FINANCIAL_FEES_UNCATEGORIZED_NEW: 'finances/fees/uncategorized_new',
  FINANCIAL_FUNDRAISER_CREDITS: 'finances/fundraiser_credits',
  FINANCIAL_FUNDRAISER_CREDITS_NEW: 'finances/fundraiser_credits/new',
  FINANCIAL_ITEMS: 'finances/items',
  FINANCIAL_ITEMS_NEW: 'finances/items/new',
  FINANCIAL_OVERVIEW: 'finances/overview',
  FINANCIAL_PAYMENTS: 'finances/payments',
  FINANCIAL_PAYMENTS_NEW: 'finances/payments/new',
  FINANCIAL_PAYMENTS_PROVIDERS: 'finances/payment_providers',
  FINANCIAL_PAYMENTS_PROVIDERS_NEW: 'finances/payment_providers/new',
  FINANCIAL_SETTINGS: 'settings/financial',
  FINANCIAL_TRANSACTIONS: 'finances/transactions',
  FIND_ORGANIZATION: 'find_organization',
  FORMS: 'forms',
  FORMS_NEW: 'forms/new',
  GETTING_STARTED: 'getting_started',
  GOOGLE_CALENDAR_VERIFICATION: 'verifications/google_calendar',
  GROUPS: 'groups',
  GROUPS_NEW: 'groups/new',
  INACTIVE_MEMBERS: 'inactive_users', // TODO: eventually change this to inactive_members when we set up redirects
  INVENTORY: 'inventory',
  INVENTORY_AUDITS: 'inventory_audits',
  INVENTORY_AUDITS_NEW: 'inventory_audits/new',
  INVENTORY_AUDITS_NOT_FOUND_ITEMS: 'not_found_items',
  INVENTORY_CHECKOUTS: 'inventory_checkouts',
  INVENTORY_DELETED: 'deleted_inventory',
  INVENTORY_NEW: 'inventory/new',
  INVENTORY_SETTINGS: 'inventory/settings',
  INVITATIONS: 'invitations',
  JOIN_NEW_ORGANIZATION: 'join_new_organization',
  JOIN_NEW_ORGANIZATION_SUCCESS: 'join_new_organization/success',
  LIBRARY: 'library',
  LIBRARY_DISTRICT: 'library/district',
  LIBRARY_NEW: 'library/new',
  LICENSES: 'licenses',
  LOGIN: 'login',
  LOGOUT: 'logout',
  MEMBERS: 'members',
  MISSING_ORGANIZATION: 'missing_organization',
  MY_FILES: 'my_files',
  MY_FORMS: 'my_forms',
  MY_GROUPS: 'my_groups',
  ONBOARDING: 'onboarding',
  ONBOARDING_ACCEPTABLE_USE_AGREEMENT: onboardingPaths.acceptable_use_agreement,
  ONBOARDING_ACCEPT_TERMS: onboardingPaths.accept_terms,
  ONBOARDING_COMPLETE: 'onboarding_complete',
  ONBOARDING_DISTRICT_INFO: onboardingPaths.district_info,
  ONBOARDING_EOY_WARNING: onboardingPaths.end_of_year_warning,
  ONBOARDING_EXTRA_VERIFICATION_NEEDED: onboardingPaths.extra_verification_needed,
  ONBOARDING_MEMBER_INFO: onboardingPaths.member_info,
  ONBOARDING_MORE_ORGANIZATION_INFO: onboardingPaths.successor_organization,
  ONBOARDING_ORGANIZATION_INFO: onboardingPaths.organization_info,
  ONBOARDING_USER_CONTACT_INFO: onboardingPaths.user_contact_info,
  ONBOARDING_USER_PROFILE_INFO: onboardingPaths.user_profile_info,
  ONBOARDING_VERIFY_EMAIL: onboardingPaths.verify_email,
  ORGANIZATIONS: 'organizations',
  ORGANIZATIONS_CLAIM: 'organizations_claim',
  OTHER: 'other',
  PARENTS: 'people/parents',
  PASSWORD_RESET: 'password_reset',
  PENDING_MEMBERS: 'pending_members',
  PEOPLE: 'people',
  PERMISSIONS: 'permissions',
  PREVIOUS_YEAR_MEMBERS: 'previous_year_members',
  PROFILE: 'profile',
  RELATIONSHIPS: 'relationships',
  REPORTS: 'reports',
  SETTINGS: 'settings',
  SETTINGS_DISTRICT_INFO: 'district_info/edit',
  SIGNUP: 'signup',
  SIGNUP_CHOOSE_ROLE: 'signup/choose_role',
  SSO: 'sso',
  STRIPE_SETTINGS: 'settings/stripe',
  STRIPE_VERIFICATION: 'stripe_verification',
  STUDENTS: 'people/students',
  STUDENT_CREDITS: 'student_finances/credits',
  STUDENT_FEES: 'student_finances/fees',
  STUDENT_INVENTORY: 'student_inventory',
  STUDENT_PAYMENTS: 'student_finances/payments',
  STUDENT_PAYMENTS_NEW: 'student_finances/payments/new',
  STUDENT_UNIFORMS: 'student_uniforms',
  UNIFORMS: 'uniforms',
  UNIFORM_CHECKOUTS: 'uniform_checkouts',
  VANCO_SETTINGS: 'settings/vanco',
  VERIFY: 'verify',
};

export const TITLES = {
  [PATHS.ADULTS_WITH_PERMISSIONS]: 'Adults with Permissions',
  [PATHS.CALENDAR]: 'Calendar',
  [PATHS.CHECKLISTS]: 'Checklists',
  [PATHS.CHECKOUTS]: 'Checkouts',
  [PATHS.COMMUNICATION]: 'Communication',
  [PATHS.COMMUNICATION_SETTINGS]: 'Communication Settings',
  [PATHS.DASHBOARD]: 'Dashboard',
  [PATHS.DIRECTORS]: 'Directors',
  [PATHS.DIRECTOR_PERMISSIONS]: 'Director Permissions',
  [PATHS.DYNAMIC_FIELDS]: 'Dynamic Fields',
  [PATHS.EMAIL_SUPPRESSIONS]: 'Bounced Emails',
  [PATHS.EMAIL_DRAFTS]: 'Email Drafts',
  [PATHS.EMAIL_NEW]: 'New Email',
  [PATHS.FILES]: 'Files',
  [PATHS.FINANCES]: 'Finances',
  [PATHS.FEEDBACK]: 'Feedback',
  [PATHS.FINANCIAL_ACCOUNTS_NEW]: 'New Financial Account',
  [PATHS.FINANCIAL_CREDITS]: 'Credits',
  [PATHS.FINANCIAL_CREDITS_NEW]: 'New Financial Credit',
  [PATHS.FINANCIAL_FUNDRAISER_CREDITS]: 'Fundraiser Credits',
  [PATHS.FINANCIAL_FUNDRAISER_CREDITS_NEW]: 'New Financial Fundraiser Credit',
  [PATHS.FINANCIAL_DEPOSITS]: 'Deposits',
  [PATHS.FINANCIAL_FEES]: 'Fees',
  [PATHS.FINANCIAL_ITEMS]: 'Items',
  [PATHS.FINANCIAL_OVERVIEW]: 'Overview',
  [PATHS.FINANCIAL_PAYMENTS]: 'Payments',
  [PATHS.FINANCIAL_PAYMENTS_NEW]: 'New Payment',
  [PATHS.FINANCIAL_TRANSACTIONS]: 'Transaction Report',
  [PATHS.FORMS]: 'Forms',
  [PATHS.GETTING_STARTED]: 'Getting Started',
  [PATHS.GOOGLE_CALENDAR_VERIFICATION]: 'Google Calendar Verification',
  [PATHS.GROUPS]: 'Groups',
  [PATHS.INACTIVE_MEMBERS]: 'Inactive Members',
  [PATHS.INVENTORY]: 'Inventory',
  [PATHS.INVENTORY_DELETED]: 'Deleted Inventory',
  [PATHS.INVENTORY_NEW]: 'Add Inventory Item',
  [PATHS.INVENTORY_SETTINGS]: 'Inventory Settings',
  [PATHS.LIBRARY]: 'Library',
  [PATHS.LIBRARY_DISTRICT]: 'District Library',
  [PATHS.LICENSES]: 'Licenses',
  [PATHS.LOGIN]: 'Login',
  [PATHS.LOGOUT]: 'Logout',
  [PATHS.MY_FILES]: 'My Files',
  [PATHS.MY_FORMS]: 'My Forms',
  [PATHS.MY_GROUPS]: 'My Groups',
  [PATHS.ONBOARDING]: 'Onboarding',
  [PATHS.ORGANIZATIONS]: 'Organizations',
  [PATHS.OTHER]: 'Other',
  [PATHS.PARENTS]: 'Parents',
  [PATHS.PASSWORD_RESET]: 'Password Reset',
  [PATHS.PREVIOUS_YEAR_MEMBERS]: 'Previous Year Members',
  [PATHS.PEOPLE]: 'People',
  [PATHS.PERMISSIONS]: 'Permissions',
  [PATHS.PROFILE]: 'Profile',
  [PATHS.REPORTS]: 'Reports',
  [PATHS.SETTINGS]: 'Settings',
  [PATHS.SETTINGS_DISTRICT_INFO]: 'Edit District Info',
  [PATHS.SIGNUP]: 'Sign Up',
  [PATHS.STRIPE_VERIFICATION]: 'Stripe Verification',
  [PATHS.STUDENTS]: 'Students',
  [PATHS.STUDENT_CREDITS]: 'My Credits',
  [PATHS.STUDENT_FEES]: 'My Fees',
  [PATHS.STUDENT_INVENTORY]: 'My Inventory',
  [PATHS.STUDENT_PAYMENTS]: 'My Payments',
  [PATHS.STUDENT_PAYMENTS_NEW]: 'Make a Payment',
  [PATHS.STUDENT_UNIFORMS]: 'My Uniforms',
  [PATHS.UNIFORMS]: 'Uniforms',
  [PATHS.UNIFORM_CHECKOUTS]: 'Uniform Checkouts',
  [PATHS.VERIFY]: 'Verify Email',
};
