// External Dependencies
import {
  CreateFinancialPaymentProviderAccountPayload,
} from '@presto-assistant/api_types/api/v1/financialPaymentProviderAccount';
import { FinancialPaymentProviders } from '@presto-assistant/api_types';
import { Form, Formik } from 'formik';
import {
  createFinancialPaymentProviderAccountSchema,
} from '@presto-assistant/api_types/schemas/financialPaymentProviderAccounts';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from '@reach/router';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';

// Internal Dependencies
import {
  CustomInput,
  EnhancedAlert,
  EnhancedCard,
  EnhancedCardContent,
  EnhancedContainer,
  Flex,
  Page,
  SaveButton,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { addNotification } from 'state/notifications/actions';
import { getErrorMessage } from 'utils/api';
import { trimValues } from 'utils/lib/trimValues';
import {
  useCreateFinancialPaymentProviderAccount,
} from 'utils/api/financialPaymentProviderAccount';
import CardActions from '@mui/material/CardActions';
import CardSectionTitle from 'components/shared/CardSectionTitle';

const FinancialPaymentProvidersNew = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    isLoading: isCreatingFinancialPaymentProviderAccount,
    mutate: createFinancialPaymentProviderAccount,
  } = useCreateFinancialPaymentProviderAccount({
    suppressErrorNotification: true,
  });

  const handleSuccess = useCallback(() => {
    dispatch(addNotification('Vanco/RevTrak synced successfully!', 'success'));

    navigate(`/${PATHS.FINANCIAL_OVERVIEW}`);
  }, [dispatch, navigate]);

  const handleFormikSubmit = useCallback((values: CreateFinancialPaymentProviderAccountPayload) => {
    setErrorMessage(null);

    const trimmedValues = trimValues(values);

    createFinancialPaymentProviderAccount(
      trimmedValues,
      {
        onError: (error) => {
          setErrorMessage(getErrorMessage(error));
        },
        onSuccess: () => {
          handleSuccess();
        },
      },
    );
  }, [createFinancialPaymentProviderAccount, handleSuccess]);

  const initialValues: CreateFinancialPaymentProviderAccountPayload = {
    credentials: {
      glCode: '',
      pcct: '',
    },
    // TODO: allow the user to select the payment provider
    financialPaymentProviderId: FinancialPaymentProviders.RevTrak,
    label: '',
  };

  const handleClickCancel = useCallback(() => {
    navigate(`/${PATHS.FINANCIAL_OVERVIEW}`);
  }, [navigate]);

  return (
    <Page>
      <EnhancedContainer>
        <EnhancedCard>
          <EnhancedCardContent>
            <CardSectionTitle>
              Create RevTrak Integration
            </CardSectionTitle>
          </EnhancedCardContent>

          <Formik<CreateFinancialPaymentProviderAccountPayload>
            initialValues={initialValues}
            onSubmit={handleFormikSubmit}
            validationSchema={createFinancialPaymentProviderAccountSchema}
          >
            {({
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <EnhancedCardContent>
                  <EnhancedAlert
                    severity="info"
                    sx={{ marginBottom: 2 }}
                  >
                    If you are unsure of your PCCT or GL Code, please contact RevTrak.
                  </EnhancedAlert>

                  <CustomInput
                    label="Custom Name"
                    name="label"
                    placeholder="(e.g. Booster Club)"
                  />

                  <CustomInput
                    label="PCCT"
                    name="credentials.pcct"
                  />

                  <CustomInput
                    label="GL Code/Account Code"
                    name="credentials.glCode"
                  />

                  <Collapse in={Boolean(errorMessage)}>
                    <EnhancedAlert severity="error">
                      {errorMessage}
                    </EnhancedAlert>
                  </Collapse>
                </EnhancedCardContent>

                <CardActions>
                  <Flex
                    gap={1}
                    justifyContent="flex-end"
                    width="100%"
                  >
                    <Button onClick={handleClickCancel}>
                      Cancel
                    </Button>

                    <SaveButton isSaving={isCreatingFinancialPaymentProviderAccount}>
                      Save
                    </SaveButton>
                  </Flex>
                </CardActions>
              </Form>
            )}
          </Formik>
        </EnhancedCard>
      </EnhancedContainer>
    </Page>
  );
};

export default FinancialPaymentProvidersNew;
