// External Dependencies
import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { CustomSelect, StyledLink } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { hasPermission } from 'state/self/selectors';
import { useGetFinancialPaymentProviderAccounts } from 'utils/api/financialPaymentProviderAccount';

// Local Typings
export interface FormValues {
  isActive: boolean;
  label: string;
  priceInCents: string | number;
  schoolYearEnding: number;
}

interface Props {
  name?: string;
  readOnly?: boolean;
}

// Component Definition
const FinancialPaymentProviderAccountSelect: FC<Props> = ({
  name = 'financialPaymentProviderAccountId',
  readOnly,
}) => {
  const navigate = useNavigate();

  const {
    data,
    isLoading,
  } = useGetFinancialPaymentProviderAccounts();

  const canWriteFinancialAccounts = useSelector(hasPermission('financialAccounts', 'write'));

  const handleClickAddFinancialPaymentProviderAccount = useCallback(() => {
    navigate(`/${PATHS.FINANCIAL_PAYMENTS_PROVIDERS_NEW}`);
  }, [navigate]);

  const helperText = useMemo(() => {
    if (isLoading) {
      return undefined;
    }

    if (canWriteFinancialAccounts) {
      return (
        <StyledLink onClick={handleClickAddFinancialPaymentProviderAccount}>
          Add financial payment provider account
        </StyledLink>
      );
    }

    if (!canWriteFinancialAccounts && !data?.data.data.length) {
      return 'Please ask a director with permission to add accounts to create a financial payment provider account.';
    }

    return undefined;
  }, [
    canWriteFinancialAccounts,
    data?.data.data,
    handleClickAddFinancialPaymentProviderAccount,
    isLoading,
  ]);

  return (
    <>
      <CustomSelect
        disabled={!data?.data.data.length}
        inputProps={{
          readOnly,
        }}
        isLoading={isLoading}
        label="Financial Payment Provider Account"
        name={name}
        options={data?.data.data.map((providerAccount) => ({
          id: providerAccount.id,
          label: providerAccount.label,
        }))}
      />

      {helperText && (
        <Box
          // This lines up with the error message label
          marginLeft={1.5}
          marginY={1}
        >
          <Typography>
            {helperText}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default FinancialPaymentProviderAccountSelect;
