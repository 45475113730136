// External Dependencies
import { FC, useCallback } from 'react';
import {
  Form,
  Formik,
  FormikHelpers,
} from 'formik';
import { createFinancialItemSchema } from '@presto-assistant/api_types/schemas/financialItems';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import ReceiptIcon from 'mdi-material-ui/Receipt';

// Internal Dependencies
import {
  Container,
  FormRouteActions,
  ShowCard,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { hasPermission } from 'state/self/selectors';
import { tableQueryParams } from 'state/table/selectors';
import { trimValues } from 'utils/lib/trimValues';
import { useGetFinancialAccounts, useGetOrganization } from 'gql/queries';
import { useGetOrganizationSchoolYear } from 'hooks/useGetOrganizationSchoolYear';

// Local Dependencies
import { useHasEnabledOnlinePaymentsForOrg } from 'hooks/useHasEnabledOnlinePaymentsForOrg';
import FormInputs from './FormInputs';
import ShowFinancialItemData from './ShowFinancialItemData';

// Local Typings
interface Props {
  financialItemData?: GQL.IFinancialItem;
  initialValues: GQL.ICreateFinancialItemInput;
  onSubmit?: (values: GQL.ICreateFinancialItemInput) => Promise<void>;
  readOnly?: boolean;
  title: string;
}

// Component Definition
const FinancialItemsForm: FC<Props> = ({
  financialItemData,
  initialValues,
  onSubmit,
  readOnly,
  title,
}) => {
  const navigate = useNavigate();

  const schoolYearEnding = useGetOrganizationSchoolYear();
  const organizationData = useGetOrganization();

  const organization = organizationData.data?.organization;

  const hasPermissionToEditFinances = useSelector(hasPermission('finances', 'edit'));
  const financialItemsParams = useSelector(tableQueryParams(`financialItems-${schoolYearEnding}`));

  const {
    data: financialAccountsData,
  } = useGetFinancialAccounts();

  const isEditing = Boolean(initialValues.label);

  const handlePressCancelOrBackButton = useCallback(() => {
    navigate(`/${PATHS.FINANCIAL_ITEMS}${financialItemsParams}`);
  }, [financialItemsParams, navigate]);

  const hasEnabledOnlinePaymentsForOrg = useHasEnabledOnlinePaymentsForOrg();

  const handleFormikSubmit = async (
    values: GQL.ICreateFinancialItemInput,
    formikProps: FormikHelpers<GQL.ICreateFinancialItemInput>,
  ) => {
    if (readOnly) {
      return;
    }

    const { setSubmitting } = formikProps;

    await onSubmit?.(trimValues(values));

    setSubmitting(false);
  };

  return (
    <Container maxWidth={512}>
      <Formik<GQL.ICreateFinancialItemInput>
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleFormikSubmit}
        validationSchema={createFinancialItemSchema}
      >
        {({
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => {
          const isFormTouched = Object.keys(touched).length > 0;

          const selectedFinancialAccount = financialAccountsData?.financialAccounts
            .find((acct) => acct.id === values.financialAccountId);

          // === false because we make sure an account exists
          // eslint-disable-next-line max-len
          const itemCannotBeAppliedToFundraiserCredits = selectedFinancialAccount?.canApplyFundraiserCredits === false;

          const showFundraiserCreditError = itemCannotBeAppliedToFundraiserCredits
            && values.canApplyFundraiserCredits;

          const showCoverStripeFeesError = Boolean(values.isOrganizationCoveringStripeFee)
            && !selectedFinancialAccount?.onlinePaymentsEnabled;

          const hasPaymentsEnabled = Boolean(selectedFinancialAccount?.onlinePaymentsEnabled)
            && hasEnabledOnlinePaymentsForOrg;

          return (
            <Form onSubmit={handleSubmit}>
              <Box mb={2}>
                <ShowCard
                  canEdit={hasPermissionToEditFinances}
                  icon={ReceiptIcon}
                  readOnly={readOnly}
                  title={title}
                >
                  {readOnly
                    ? <ShowFinancialItemData financialItemData={financialItemData} />
                    : (
                      <FormInputs
                        hasPaymentsEnabled={hasPaymentsEnabled}
                        isOrganizationCoveringStripeFee={Boolean(
                          values.isOrganizationCoveringStripeFee,
                        )}
                        organizationName={organization?.label ?? ''}
                        showCoverStripeFeesError={showCoverStripeFeesError}
                        showFundraiserCreditError={showFundraiserCreditError}
                      />
                    )}
                </ShowCard>
              </Box>

              {!readOnly && (
                <FormRouteActions
                  context="Financial Item"
                  isEditing={isEditing}
                  isFormTouched={isFormTouched}
                  isSubmitting={isSubmitting}
                  onPressCancelOrBackButton={handlePressCancelOrBackButton}
                />
              )}
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default FinancialItemsForm;
