// External Dependencies
import { FC, useCallback } from 'react';
import { FeatureFlag } from '@presto-assistant/api_types';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';

// Internal Dependencies
import { EnhancedAlert, StyledLink } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { hasFeatureFlag, hasPermission, isDirector } from 'state/self/selectors';
import { useGetOrganization } from 'gql/queries';
import { useHasEnabledOnlinePaymentsForOrg } from 'hooks/useHasEnabledOnlinePaymentsForOrg';

// Component Definition
const OnlinePaymentsAlert: FC = () => {
  const navigate = useNavigate();
  const canReadPayments = useSelector(hasPermission('payments', 'read'));
  const isUserDirector = useSelector(isDirector);
  const isOnlinePaymentsFeatureFlagOn = useSelector(hasFeatureFlag(FeatureFlag.OnlinePayments));

  const {
    data: orgData,
  } = useGetOrganization();
  const handleClickInviteDFA = () => {
    navigate(`/${PATHS.SETTINGS}#invite-district-admin-form`);
  };

  const organization = orgData?.organization;

  const district = organization?.district;

  const areOnlinePaymentsEnabledForDistrict = district?.areOnlinePaymentsEnabled;
  const areOnlinePaymentsEnabledForOrg = useHasEnabledOnlinePaymentsForOrg();

  const hasStripeId = organization?.hasStripeId;
  const hasVancoPcct = organization?.hasVancoPcct;

  const hasPaymentProcessor = hasStripeId || hasVancoPcct;

  const areOnlinePaymentsEnabled = areOnlinePaymentsEnabledForDistrict
    && areOnlinePaymentsEnabledForOrg
    && hasPaymentProcessor;

  const handleClickSettingsLink = useCallback(() => {
    navigate(`/${PATHS.SETTINGS}#integrations`);
  }, [navigate]);

  if (!canReadPayments) {
    return null;
  }

  if (!isOnlinePaymentsFeatureFlagOn) {
    return null;
  }

  if (!isUserDirector) {
    return null;
  }

  if (areOnlinePaymentsEnabledForDistrict && !areOnlinePaymentsEnabled) {
    return (
      <EnhancedAlert sx={{ mb: 2 }}>
        You have not set up online payments. Go to
        {' '}
        <StyledLink
          onClick={handleClickSettingsLink}
        >
          settings
        </StyledLink>
        {' '}
        to get started.
      </EnhancedAlert>
    );
  }

  if (district && !areOnlinePaymentsEnabledForDistrict) {
    return (
      <EnhancedAlert sx={{ mb: 2 }}>
        {/* eslint-disable-next-line max-len */}
        Ask your administrator to enable online payments for your district via their settings page if you wish to enable this feature.
      </EnhancedAlert>
    );
  }

  if (!district) {
    return (
      <EnhancedAlert
        action={(
          <Button
            onClick={handleClickInviteDFA}
          >
            Get Started
          </Button>
        )}
        sx={{ mb: 2 }}
      >
        Connect with a district account to start using online payments.
      </EnhancedAlert>
    );
  }

  return null;
};

export default OnlinePaymentsAlert;
