// External Dependencies
import { FC, Fragment, useMemo } from 'react';
import { FeatureFlag } from '@presto-assistant/api_types';
import { useSelector } from 'react-redux';
import AccountGroupIcon from 'mdi-material-ui/AccountGroup';
import AccountTieIcon from 'mdi-material-ui/AccountTie';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BankTransferInIcon from 'mdi-material-ui/BankTransferIn';
import BusinessIcon from '@mui/icons-material/Business';
import CalendarIcon from 'mdi-material-ui/CalendarMultiselect';
import CardAccountDetailsIcon from 'mdi-material-ui/CardAccountDetails';
import CartIcon from 'mdi-material-ui/Cart';
import CategoryIcon from '@mui/icons-material/Category';
import CertificateOutline from 'mdi-material-ui/CertificateOutline';
import ChecklistsIcon from 'mdi-material-ui/CheckboxMultipleOutline';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import EmailIcon from '@mui/icons-material/Email';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import FolderIcon from '@mui/icons-material/Folder';
import HomeIcon from '@mui/icons-material/Home';
import KeyIcon from 'mdi-material-ui/Key';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import List from '@mui/material/List';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PeopleIcon from '@mui/icons-material/People';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import ReceiptIcon from 'mdi-material-ui/Receipt';
import TableChartIcon from '@mui/icons-material/TableChart';
import TieIcon from 'mdi-material-ui/Tie';
import WidgetsIcon from '@mui/icons-material/Widgets';

// Internal Dependencies
import { NavMenuItem } from 'components/shared';
import { NavMenuItemProps } from 'components/shared/NavMenuItem';
import { PATHS, TITLES } from 'utils/constants/routes';
import {
  hasFeatureFlag,
  hasPermission,
  isFullAccessDfa,
  isStudent,
} from 'state/self/selectors';
import { showFinancialDeposits } from 'utils/constants';
import { tableQueryParams } from 'state/table/selectors';
import { useDashboardOrgData } from 'hooks/useDashboardOrgData';
import { useGetOrganizationSchoolYear } from 'hooks/useGetOrganizationSchoolYear';
import { useGetSelf } from 'gql/queries';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Dependencies
import SideNavSubtitle from './SideNavSubtitle';

type SideNavGroup = 'Inventory' | 'People' | 'Finances' | 'Other' | 'Support';

interface NavMenuItem {
  icon: NavMenuItemProps['icon'];
  match: string;
  showNewChip?: boolean;
  text: NavMenuItemProps['text'];
  to: NavMenuItemProps['to'];
  when: boolean;
}

export interface SideNavItemsByGroup {
  group: SideNavGroup | null;
  navMenuItems: NavMenuItem[];
}

const useDfaSideNavLinks = (): SideNavItemsByGroup[] => {
  const directorPermissionParams = useSelector(tableQueryParams('directorPermissions'));
  const districtFormsParams = useSelector(tableQueryParams('districtForms'));
  const districtOrgParams = useSelector(tableQueryParams('districtOrganizations'));
  const districtInventoryParams = useSelector(tableQueryParams('districtInventoryItems'));
  const districtLibraryParams = useSelector(tableQueryParams('districtLibraryItems'));
  const districtUniformParams = useSelector(tableQueryParams('districtUniforms'));
  const dynamicFieldsParams = useSelector(tableQueryParams('dynamicFields'));
  const licensesParams = useSelector(tableQueryParams('licenses'));

  const homeGroup = useMemo<SideNavItemsByGroup>(() => ({
    group: null,
    navMenuItems: [
      {
        icon: HomeIcon,
        match: `/${PATHS.DISTRICT_ADMIN}/${PATHS.DASHBOARD}`,
        text: TITLES[PATHS.DASHBOARD],
        to: `/${PATHS.DISTRICT_ADMIN}/${PATHS.DASHBOARD}`,
        when: true,
      },
      {
        icon: BusinessIcon,
        match: `/${PATHS.DISTRICT_ADMIN}/${PATHS.ORGANIZATIONS}`,
        text: TITLES[PATHS.ORGANIZATIONS],
        to: `/${PATHS.DISTRICT_ADMIN}/${PATHS.ORGANIZATIONS}${districtOrgParams}`,
        when: true,
      },
    ],
  }), [
    districtOrgParams,
  ]);

  const inventoryGroup = useMemo<SideNavItemsByGroup>(() => ({
    group: 'Inventory',
    navMenuItems: [
      {
        icon: WidgetsIcon,
        match: `/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY}`,
        text: TITLES[PATHS.INVENTORY],
        to: `/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY}${districtInventoryParams}`,
        when: true,
      },
      {
        icon: LibraryBooksIcon,
        match: `/${PATHS.DISTRICT_ADMIN}/${PATHS.LIBRARY}`,
        text: TITLES[PATHS.LIBRARY],
        to: `/${PATHS.DISTRICT_ADMIN}/${PATHS.LIBRARY}${districtLibraryParams}`,
        when: true,
      },
      {
        icon: TieIcon,
        match: `/${PATHS.DISTRICT_ADMIN}/${PATHS.UNIFORMS}`,
        text: TITLES[PATHS.UNIFORMS],
        to: `/${PATHS.DISTRICT_ADMIN}/${PATHS.UNIFORMS}${districtUniformParams}`,
        when: true,
      },
    ],
  }), [
    districtInventoryParams,
    districtLibraryParams,
    districtUniformParams,
  ]);

  const otherGroup = useMemo<SideNavItemsByGroup>(() => ({
    group: 'Other',
    navMenuItems: [
      {
        icon: DynamicFormIcon,
        match: `/${PATHS.DISTRICT_ADMIN}/${PATHS.FORMS}`,
        showNewChip: new Date() < new Date('2024-06-01'),
        text: 'District Forms',
        to: `/${PATHS.DISTRICT_ADMIN}/${PATHS.FORMS}${districtFormsParams}`,
        when: true,
      },
      {
        icon: CategoryIcon,
        match: `/${PATHS.DISTRICT_ADMIN}/${PATHS.DYNAMIC_FIELDS}`,
        text: TITLES[PATHS.DYNAMIC_FIELDS],
        to: `/${PATHS.DISTRICT_ADMIN}/${PATHS.DYNAMIC_FIELDS}${dynamicFieldsParams}`,
        when: true,
      },
      {
        icon: CertificateOutline,
        match: `/${PATHS.DISTRICT_ADMIN}/${PATHS.LICENSES}`,
        text: TITLES[PATHS.LICENSES],
        to: `/${PATHS.DISTRICT_ADMIN}/${PATHS.LICENSES}${licensesParams}`,
        when: true,
      },
      {
        icon: KeyIcon,
        match: `/${PATHS.DISTRICT_ADMIN}/${PATHS.DIRECTOR_PERMISSIONS}`,
        text: TITLES[PATHS.DIRECTOR_PERMISSIONS],
        to: `/${PATHS.DISTRICT_ADMIN}/${PATHS.DIRECTOR_PERMISSIONS}${directorPermissionParams}`,
        when: true,
      },
      {
        icon: AssessmentIcon,
        match: `/${PATHS.DISTRICT_ADMIN}/${PATHS.REPORTS}`,
        text: TITLES[PATHS.REPORTS],
        to: `/${PATHS.DISTRICT_ADMIN}/${PATHS.REPORTS}`,
        when: true,
      },
    ],
  }), [
    directorPermissionParams,
    districtFormsParams,
    dynamicFieldsParams,
    licensesParams,
  ]);

  const supportGroup = useMemo<SideNavItemsByGroup>(() => ({
    group: 'Support',
    navMenuItems: [
      {
        icon: FeedbackOutlinedIcon,
        match: `/${PATHS.DISTRICT_ADMIN}/${PATHS.FEEDBACK}`,
        text: TITLES[PATHS.FEEDBACK],
        to: `/${PATHS.DISTRICT_ADMIN}/${PATHS.FEEDBACK}`,
        when: true,
      },
    ],
  }), []);

  return useMemo(() => [
    homeGroup,
    inventoryGroup,
    otherGroup,
    supportGroup,
  ], [
    homeGroup,
    inventoryGroup,
    otherGroup,
    supportGroup,
  ]);
};

export const useMemberSideNavLinks = ({
  loggedIn,
  loggedInAsDirector,
}: {
  loggedIn: boolean;
  loggedInAsDirector: boolean;
}): SideNavItemsByGroup[] => {
  const { self } = useSelfQuery();
  const orgData = useDashboardOrgData();
  const schoolYearEnding = useGetOrganizationSchoolYear();

  const isStudentMember = useSelector(isStudent);

  const directorParams = useSelector(tableQueryParams('directors'));
  const emailParams = useSelector(tableQueryParams('emails'));
  const fileDirectoryParams = useSelector(tableQueryParams('fileDirectories'));
  const financialCreditsParams = useSelector(tableQueryParams('financialCredits'));
  const financialFundraiserCreditsParams = useSelector(tableQueryParams('financialFundraiserCredits'));
  const financialDepositsParams = useSelector(tableQueryParams('financialDeposits'));
  const financialFeesParams = useSelector(tableQueryParams(`financialFees-${schoolYearEnding}`));
  const financialItemsParams = useSelector(tableQueryParams(`financialItems-${schoolYearEnding}`));
  const financialPaymentsParams = useSelector(tableQueryParams(`financialPayments-${schoolYearEnding}`));
  const financialTransactionsParams = useSelector(tableQueryParams('financialTransactions'));
  const groupParams = useSelector(tableQueryParams('groups'));
  const inventoryItemsParams = useSelector(tableQueryParams('inventoryItems'));
  const parentParams = useSelector(tableQueryParams('parents'));
  const studentParams = useSelector(tableQueryParams('students'));
  const libraryItemsParams = useSelector(tableQueryParams('libraryItems'));
  const uniformParams = useSelector(tableQueryParams('uniforms'));
  const myFilesParams = useSelector(tableQueryParams('myFiles'));
  const myFeesParams = useSelector(tableQueryParams('myFinancialFees'));
  const myFormsParams = useSelector(tableQueryParams('myForms'));
  const myPaymentsParams = useSelector(tableQueryParams('myFinancialPayments'));
  const myCreditsParams = useSelector(tableQueryParams('myFinancialCredits'));

  const hasFinancesFeatureFlag = useSelector(hasFeatureFlag(FeatureFlag.Finances));

  const canReadFinances = useSelector(hasPermission('finances', 'read'));
  const canReadFiles = useSelector(hasPermission('s3Uploads', 'read'));
  const canReadGroups = useSelector(hasPermission('groups', 'read'));
  const canReadInventory = useSelector(hasPermission('inventory', 'read'));
  const canReadLibrary = useSelector(hasPermission('library', 'read'));
  const canReadPayments = useSelector(hasPermission('payments', 'read'));
  const canReadUniforms = useSelector(hasPermission('uniforms', 'read'));
  const canReadUsers = useSelector(hasPermission('users', 'read'));

  const homeGroup = useMemo<SideNavItemsByGroup>(() => ({
    group: null,
    navMenuItems: [
      {
        icon: LightbulbIcon,
        match: PATHS.GETTING_STARTED,
        text: TITLES[PATHS.GETTING_STARTED],
        to: `/${PATHS.GETTING_STARTED}`,
        when: Boolean(loggedInAsDirector
          && (orgData && (!orgData?.active || orgData?.isFreeTrial))),
      },
      {
        icon: HomeIcon,
        match: PATHS.DASHBOARD,
        text: TITLES[PATHS.DASHBOARD],
        to: `/${PATHS.DASHBOARD}`,
        when: loggedIn,
      },
      {
        icon: CalendarIcon,
        match: PATHS.CALENDAR,
        text: TITLES[PATHS.CALENDAR],
        to: `/${PATHS.CALENDAR}`,
        when: Boolean(self?.currentOrgId && orgData?.hasGoogleCalendar),
      },
      {
        icon: EmailIcon,
        match: PATHS.COMMUNICATION,
        text: TITLES[PATHS.COMMUNICATION],
        to: `/${PATHS.COMMUNICATION}${emailParams}`,
        when: loggedIn,
      },
      {
        icon: FolderIcon,
        match: PATHS.FILES,
        text: TITLES[PATHS.FILES],
        to: `/${PATHS.FILES}${fileDirectoryParams}`,
        when: loggedIn && canReadFiles,
      },
      {
        icon: FolderIcon,
        match: PATHS.MY_FILES,
        text: TITLES[PATHS.MY_FILES],
        to: `/${PATHS.MY_FILES}${myFilesParams}`,
        when: loggedIn && !canReadFiles,
      },
    ],
  }), [
    canReadFiles,
    emailParams,
    fileDirectoryParams,
    loggedIn,
    loggedInAsDirector,
    myFilesParams,
    orgData,
    self?.currentOrgId,
  ]);

  const inventoryGroup = useMemo<SideNavItemsByGroup>(() => ({
    group: 'Inventory',
    navMenuItems: [
      {
        icon: WidgetsIcon,
        match: PATHS.INVENTORY,
        text: TITLES[PATHS.INVENTORY],
        to: `/${PATHS.INVENTORY}${inventoryItemsParams}`,
        when: canReadInventory,
      },
      {
        icon: LibraryBooksIcon,
        match: PATHS.LIBRARY,
        text: TITLES[PATHS.LIBRARY],
        to: `/${PATHS.LIBRARY}${libraryItemsParams}`,
        when: canReadLibrary,
      },
      {
        icon: TieIcon,
        match: PATHS.UNIFORMS,
        text: TITLES[PATHS.UNIFORMS],
        to: `/${PATHS.UNIFORMS}${uniformParams}`,
        when: canReadUniforms,
      },
      {
        icon: WidgetsIcon,
        match: PATHS.STUDENT_INVENTORY,
        text: TITLES[PATHS.STUDENT_INVENTORY],
        to: `/${PATHS.STUDENT_INVENTORY}`,
        when: Boolean(isStudentMember),
      },
      {
        icon: TieIcon,
        match: PATHS.STUDENT_UNIFORMS,
        text: TITLES[PATHS.STUDENT_UNIFORMS],
        to: `/${PATHS.STUDENT_UNIFORMS}`,
        when: Boolean(isStudentMember),
      },
    ],
  }), [
    canReadInventory,
    canReadLibrary,
    canReadUniforms,
    inventoryItemsParams,
    isStudentMember,
    libraryItemsParams,
    uniformParams,
  ]);

  const peopleGroup = useMemo<SideNavItemsByGroup>(() => ({
    group: 'People',
    navMenuItems: [
      {
        icon: AccountGroupIcon,
        match: PATHS.GROUPS,
        text: TITLES[PATHS.GROUPS],
        to: `/${PATHS.GROUPS}${groupParams}`,
        when: canReadGroups,
      },
      {
        icon: CardAccountDetailsIcon,
        match: PATHS.STUDENTS,
        text: TITLES[PATHS.STUDENTS],
        to: `/${PATHS.STUDENTS}${studentParams}`,
        when: canReadUsers,
      },
      {
        icon: PeopleIcon,
        match: PATHS.PARENTS,
        text: TITLES[PATHS.PARENTS],
        to: `/${PATHS.PARENTS}${parentParams}`,
        when: canReadUsers,
      },
      {
        icon: AccountTieIcon,
        match: PATHS.DIRECTORS,
        text: TITLES[PATHS.DIRECTORS],
        to: `/${PATHS.DIRECTORS}${directorParams}`,
        when: loggedIn,
      },
    ],
  }), [
    canReadGroups,
    canReadUsers,
    directorParams,
    groupParams,
    loggedIn,
    parentParams,
    studentParams,
  ]);

  const financesGroup = useMemo<SideNavItemsByGroup>(() => ({
    group: 'Finances',
    navMenuItems: hasFinancesFeatureFlag ? [
      {
        icon: DashboardIcon,
        match: PATHS.FINANCIAL_OVERVIEW,
        text: TITLES[PATHS.FINANCIAL_OVERVIEW],
        to: `/${PATHS.FINANCIAL_OVERVIEW}`,
        when: canReadFinances && canReadPayments,
      },
      {
        icon: ReceiptIcon,
        match: PATHS.FINANCIAL_ITEMS,
        text: TITLES[PATHS.FINANCIAL_ITEMS],
        to: `/${PATHS.FINANCIAL_ITEMS}${financialItemsParams}`,
        when: canReadFinances,
      },
      {
        icon: CartIcon,
        match: PATHS.FINANCIAL_FEES,
        text: TITLES[PATHS.FINANCIAL_FEES],
        to: `/${PATHS.FINANCIAL_FEES}${financialFeesParams}`,
        when: canReadFinances,
      },
      {
        icon: CartIcon,
        match: PATHS.STUDENT_FEES,
        text: TITLES[PATHS.STUDENT_FEES],
        to: `/${PATHS.STUDENT_FEES}${myFeesParams}`,
        when: !canReadFinances,
      },
      {
        icon: LocalAtmIcon,
        match: PATHS.FINANCIAL_PAYMENTS,
        text: TITLES[PATHS.FINANCIAL_PAYMENTS],
        to: `/${PATHS.FINANCIAL_PAYMENTS}${financialPaymentsParams}`,
        when: canReadPayments,
      },
      {
        icon: LocalAtmIcon,
        match: PATHS.STUDENT_PAYMENTS,
        text: TITLES[PATHS.STUDENT_PAYMENTS],
        to: `/${PATHS.STUDENT_PAYMENTS}${myPaymentsParams}`,
        when: !canReadPayments,
      },
      {
        icon: PriceChangeIcon,
        match: PATHS.FINANCIAL_CREDITS,
        text: TITLES[PATHS.FINANCIAL_CREDITS],
        to: `/${PATHS.FINANCIAL_CREDITS}${financialCreditsParams}`,
        when: canReadPayments,
      },
      {
        icon: PriceChangeIcon,
        match: PATHS.STUDENT_CREDITS,
        text: TITLES[PATHS.STUDENT_CREDITS],
        to: `/${PATHS.STUDENT_CREDITS}${myCreditsParams}`,
        when: !canReadPayments,
      },
      {
        icon: CurrencyExchangeIcon,
        match: PATHS.FINANCIAL_FUNDRAISER_CREDITS,
        text: TITLES[PATHS.FINANCIAL_FUNDRAISER_CREDITS],
        to: `/${PATHS.FINANCIAL_FUNDRAISER_CREDITS}${financialFundraiserCreditsParams}`,
        when: canReadPayments,
      },
      {
        icon: BankTransferInIcon,
        match: PATHS.FINANCIAL_DEPOSITS,
        text: TITLES[PATHS.FINANCIAL_DEPOSITS],
        to: `/${PATHS.FINANCIAL_DEPOSITS}${financialDepositsParams}`,
        when: canReadPayments && showFinancialDeposits,
      },
      {
        icon: TableChartIcon,
        match: PATHS.FINANCIAL_TRANSACTIONS,
        text: TITLES[PATHS.FINANCIAL_TRANSACTIONS],
        to: `/${PATHS.FINANCIAL_TRANSACTIONS}${financialTransactionsParams}`,
        when: canReadPayments,
      },
    ] : [],
  }), [
    canReadFinances,
    canReadPayments,
    financialCreditsParams,
    financialDepositsParams,
    financialFeesParams,
    financialFundraiserCreditsParams,
    financialItemsParams,
    financialPaymentsParams,
    financialTransactionsParams,
    hasFinancesFeatureFlag,
    myCreditsParams,
    myFeesParams,
    myPaymentsParams,
  ]);

  const otherGroup = useMemo<SideNavItemsByGroup>(() => ({
    group: 'Other',
    navMenuItems: [
      {
        icon: ChecklistsIcon,
        match: PATHS.CHECKLISTS,
        text: TITLES[PATHS.CHECKLISTS],
        to: `/${PATHS.CHECKLISTS}`,
        when: loggedInAsDirector,
      },
      {
        icon: DynamicFormIcon,
        match: PATHS.FORMS,
        showNewChip: new Date() < new Date('2024-06-01'),
        text: TITLES[PATHS.FORMS],
        to: `/${PATHS.FORMS}`,
        when: loggedInAsDirector,
      },
      {
        icon: DynamicFormIcon,
        match: PATHS.MY_FORMS,
        text: TITLES[PATHS.MY_FORMS],
        to: `/${PATHS.MY_FORMS}${myFormsParams}`,
        when: loggedIn && !loggedInAsDirector,
      },
      {
        icon: FeedbackOutlinedIcon,
        match: PATHS.FEEDBACK,
        text: TITLES[PATHS.FEEDBACK],
        to: `/${PATHS.FEEDBACK}`,
        when: loggedInAsDirector,
      },
    ],
  }), [
    loggedIn,
    loggedInAsDirector,
    myFormsParams,
  ]);

  return useMemo(() => [
    homeGroup,
    peopleGroup,
    inventoryGroup,
    financesGroup,
    otherGroup,
  ], [
    financesGroup,
    homeGroup,
    inventoryGroup,
    otherGroup,
    peopleGroup,
  ]);
};

const useSideNavLinks = (): SideNavItemsByGroup[] => {
  const {
    data,
  } = useGetSelf();

  const isFullAccessAdminUser = useSelector(isFullAccessDfa);

  const {
    currentOrgId,
    hasDistrict,
    id: memberId,
    role,
    userOrgData,
  } = data?.self ?? {};

  const isMemberIdFoundInUserOrgs = userOrgData?.some(
    (org: GQL.IUserOrganization) => org?.member?.id === memberId,
  );

  // This is a DFA who is signed in under a specific organization
  // hasDistrict means they have a member profile as a DFA or DFA asst
  // not having a member id in the user orgs means they are not a member of the org
  const loggedInAsDistrictAdmin = hasDistrict && !isMemberIdFoundInUserOrgs;

  const currentOrg = userOrgData?.find(
    (org: GQL.IUserOrganization) => org?.organizationId === currentOrgId
      && (org?.member?.id === memberId || loggedInAsDistrictAdmin),
  );

  // We check if the current user is an admin in the current org
  const isAdminInCurrentOrg = currentOrg?.admin;

  const loggedInAsDirector = Boolean((role?.label === 'Adult' && isAdminInCurrentOrg) || isFullAccessAdminUser);
  const loggedInAsParent = role?.label === 'Adult'
    && !isAdminInCurrentOrg
    && !loggedInAsDistrictAdmin
    && !isFullAccessAdminUser;
  const loggedInAsStudent = role?.label === 'Student' && !isFullAccessAdminUser;
  const loggedIn = Boolean(data?.self);

  const dfaSideNavLinks = useDfaSideNavLinks();
  const memberSideNavLinks = useMemberSideNavLinks({
    loggedIn,
    loggedInAsDirector,
  });

  if (loggedInAsDirector || loggedInAsParent || loggedInAsStudent) {
    return memberSideNavLinks;
  }

  if (loggedInAsDistrictAdmin) {
    return dfaSideNavLinks;
  }

  return [];
};

interface Props {
  onLinkClick?: () => void;
}

const SideNavLinks: FC<Props> = ({
  onLinkClick,
}) => {
  const sideNavLinks = useSideNavLinks();

  if (!sideNavLinks.length) {
    return null;
  }

  return (
    <List role="menu">
      {sideNavLinks.map((group) => {
        const filteredNavItems = group.navMenuItems
          .filter((navMenuItem) => navMenuItem.when);

        if (!filteredNavItems.length) {
          return null;
        }

        return (
          <Fragment key={group.group}>
            {group.group && <SideNavSubtitle subtitleText={group.group} />}
            {filteredNavItems.map((navMenuItem) => (
              <NavMenuItem
                icon={navMenuItem.icon}
                key={`${navMenuItem.text}`}
                match={navMenuItem.match}
                onClick={onLinkClick}
                showNewChip={navMenuItem.showNewChip}
                text={navMenuItem.text}
                to={navMenuItem.to}
              />
            ))}
          </Fragment>
        );
      })}
    </List>
  );
};

export default SideNavLinks;
