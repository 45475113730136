// External Dependencies
import { useMemo, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MUISelect, { SelectProps as MUISelectProps } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Local Typings
// error and required are throwing strange warnings if we expect a boolean instead of a function.
// The reason is unknown for now. This works as expected if passing type any.
export interface SelectProps extends MUISelectProps {
  error?: any;
  errorMessage?: string | null;
  forceError?: boolean;
  fullWidth?: boolean;
  hideLabel?: boolean;
  label: string;
  name: string;
  options: Array<{ id: number | string; label: string }>;
  required?: any;
  testId?: string;
  value: number | string;
}

// Local Variables
// The height of a menu item
const ITEM_HEIGHT = 48;

const StyledFormControl = styled(FormControl)({
  '.formControl': {
    minWidth: 200,
    textAlign: 'left',
  },
  '.select': {
    // This lets the user see a partial item and indicates scroll as an option
    maxHeight: ITEM_HEIGHT * 4.6,
  },
});

// Component Definition
const Select = ({
  error = false,
  errorMessage = null,
  forceError = false,
  fullWidth = true,
  hideLabel = false,
  label,
  name,
  onChange,
  options,
  required = false,
  testId,
  value,
  variant = 'filled',
  ...props
}: SelectProps): JSX.Element => {
  const [isTouched, setIsTouched] = useState(false);

  const handleBlur = () => {
    setIsTouched(true);
  };

  const hasError = (error && isTouched) || forceError;

  const selectOptions = useMemo(() => (
    <>
      {!required && (
        <option
          aria-label="None"
          value=""
        />
      )}
      {options.length && options.map(({ id, label: optionLabel }) => (
        <option
          key={id}
          value={id}
        >
          {optionLabel}
        </option>
      ))}
    </>
  ), [options, required]);

  return (
    <StyledFormControl
      fullWidth={fullWidth}
      variant={variant}
    >
      {!hideLabel && (
        <InputLabel htmlFor={name}>
          {label}
        </InputLabel>
      )}

      <MUISelect
        MenuProps={{ PopoverClasses: { paper: 'select' } }}
        error={hasError}
        inputProps={{
          'data-testid': testId,
          id: name,
        }}
        name={name}
        native
        onBlur={handleBlur}
        onChange={onChange}
        type="text"
        value={value}
        {...props}
      >
        {selectOptions}
      </MUISelect>

      {(hasError || forceError) && (
        <FormHelperText>
          <Typography color="error">
            {errorMessage}
          </Typography>
        </FormHelperText>
      )}
    </StyledFormControl>
  );
};

export default Select;
