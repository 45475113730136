// External Dependencies
import { FC, useCallback } from 'react';
import { FeatureFlag } from '@presto-assistant/api_types';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ViewIcon from '@mui/icons-material/ArrowForward';

// Internal Dependencies
import {
  DashboardCard,
  EnhancedAlert,
  ListItemWithSecondaryAction,
  SubscriberAddButton,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { hasFeatureFlag, hasPermission } from 'state/self/selectors';
import { useGetFinancialAccounts } from 'gql/queries';
import { useIsOnlinePaymentsFeatureAvailable } from 'hooks/useCanMakePayments';

// Component Definition
const FinancialAccountsCard: FC = () => {
  const navigate = useNavigate();

  const canWriteAccounts = useSelector(hasPermission('financialAccounts', 'write'));

  const {
    areOnlinePaymentsEnabled,
    hasPaymentProcessor,
  } = useIsOnlinePaymentsFeatureAvailable();
  const isOnlinePaymentsFeatureAvailable = areOnlinePaymentsEnabled && hasPaymentProcessor;

  const hasVancoPaymentsFeatureFlag = useSelector(hasFeatureFlag(FeatureFlag.VancoPayments));

  const {
    data,
    loading,
  } = useGetFinancialAccounts();

  const handleNavigateToNewAccount = useCallback(() => {
    navigate(`/${PATHS.FINANCIAL_ACCOUNTS_NEW}`);
  }, [navigate]);

  const handleClickAccount = useCallback((accountId: string) => () => {
    navigate(`/${PATHS.FINANCIAL_ACCOUNTS}/${accountId}`);
  }, [navigate]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <DashboardCard
      cardActions={canWriteAccounts ? (
        <SubscriberAddButton
          label="Financial Account"
          onClick={handleNavigateToNewAccount}
        />
      ) : undefined}
      content={(
        <>
          {data?.financialAccounts.length ? (
            <Paper
              sx={{ mb: 2 }}
              variant="outlined"
            >
              <List>
                {data?.financialAccounts.map((account) => (
                  <ListItemWithSecondaryAction
                    key={account.id}
                    primaryText={account.label}
                    secondaryAction={{
                      buttonIcon: <ViewIcon />,
                      buttonText: 'View',
                      onClick: handleClickAccount(account.id),
                    }}
                    secondaryText={isOnlinePaymentsFeatureAvailable && (
                      <>
                        Online payments are
                        {' '}
                        <strong>
                          {(account.onlinePaymentsEnabled
                            || account.financialPaymentProviderAccountId)
                            ? 'enabled' : 'disabled'}
                        </strong>
                      </>
                    )}
                  />
                ))}
              </List>
            </Paper>
          ) : (
            <Typography
              color="textSecondary"
              gutterBottom={!canWriteAccounts}
              variant="body2"
            >
              No financial accounts for this organization.
            </Typography>
          )}

          {!canWriteAccounts && (
            <Typography
              color="textSecondary"
              variant="body2"
            >
              You do not have permission to add financial accounts.
              {' '}
              Please contact your district administrator.
            </Typography>
          )}

          <EnhancedAlert sx={{ mt: 2 }}>
            Financial accounts categorize fees in your organization.
            {' '}
            To receive online payments from parents using the Stripe {hasVancoPaymentsFeatureFlag ? ' or Vanco' : ''}{' '}
            integration,
            {' '}
            you must have a Financial Account set up.
          </EnhancedAlert>
        </>
      )}
      title="Financial Accounts"
    />
  );
};

export default FinancialAccountsCard;
